.table-header {
    font-size: 12px;
    font-weight: bold;
    background-color: aqua;
  }
  
  .table-row {
    font-size: 12px;
  }
  
  .form-label {
    font-size: 16px;
  }
  
  .form-input {
    font-size: 14px;
    width: 150px;
  }
  

  .container {
    padding: 20px;
  }
  .container td{
    padding: 5px;
    margin: 0;
  }
  
  .success-message {
    color: green;
    font-size: 16px;
  }
  
  .error-message {
    color: red;
    font-size: 16px;
  }
  .salesEntry{
    font-size: 12px;
   
  }
  .salesEntry input{
    width: 150px;
    margin: 0;
    height: 30px;
  }
  h3{
    text-align: center;
    border-bottom: 1px solid black;
  }

  .container-fluid{
    font-family: Arial, Helvetica, sans-serif;
  }