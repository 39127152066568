.search-form {
    display: flex;
    align-items: center;
  }
  
  .search-form label {
    margin-right: 10px;
  }
  
  .search-form input,
  .search-form select,
  .search-form button {
    margin-left: 5px;
  }
  
  .modal-body {
    overflow-x: auto;
  }
  
  .table-responsive {
    max-height: 400px;
    overflow-y: auto;

  }
  .table {
    font-size: 14px; /* Adjust as needed */
  }