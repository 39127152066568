
.status{
    text-align: center;
}

.cbooking p{
   color: blueviolet;
}

.cbooking td{
    text-align: center;
}

#pdoc{
    
    border: 1px solid black;
    background-color: white;
    margin-top: 20px;

}
#pdoc:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
h6{
    text-align: center;
    border-bottom: 1px dotted dodgerblue ;
    padding: 10px;
}
#bt{
    text-align: justify;
}
